:root {
  --white-clr: #ffffff;
  --navyblue-clr: #0e2b5c;
  --clr-body: hsl(230, 43%, 97%);
  --black-dark: #333333;
}
body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 62.5% !important;
}
html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
h2 {
  font-size: 2.8rem;
  font-weight: 600;
}

p {
  font-size: 1rem;
}
.btn:focus,
.btn:active,
button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}
.page-title {
  margin-bottom: 60px;
}
.page-title h2 {
  color: var(--black-dark);
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 15px !important;
}
.page-title p {
  color: #3e3f40;
  font-weight: 400;
  line-height: 1.6em;
  font-size: 18px;
}
@media screen and (max-width: 560px) {
  .page-title {
    margin-bottom: 30px;
  }
  .page-title h2 {
    font-size: 28px;
  }
}
